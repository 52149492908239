import { getCaducitat, getDurada, hasEtiquetaDocumental, isPeli, removeHtmlTags, trimBR } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import Boto from 'components/ui/Boto';
import Icona from 'components/3cat/ui/Icona';
import { useUser } from 'hooks/useUser';
import __filter from 'lodash/filter';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getLogoDirecte } from './canalHelper';
import styles from './carrussel.module.scss';

// NORMALITZAR DURADA QUAN ARRIBA AMB FORMAT DE dd:hh:mm:ss
const _sliceDurada = (durada) => {
    if (durada.length === 11) {
        durada = durada.slice(0, 8);
    }
    return getDurada(durada);
};

const getLogoDiferit = (element) => {
    const imatges =
        element.tipologia === 'DTY_VIDEO_MM'
            ? element?.programes_tv?.[0]?.imatges || element?.programes_radio?.[0]?.imatges
            : element.tipologia === 'WCR_AUDIO_MM'
            ? element?.programes_radio?.[0]?.imatges
            : element.imatges;
    return __filter(imatges, function (o) {
        return (o.rel_name === 'img_logo' || o.rel_name === 'IMG_LOGO') && o.mida === 'MASTER';
    });
};

const _textBoto = (element) => {
    return !__isEmpty(element.call_to_action)
        ? element.call_to_action
        : element.canals?.[0].id.replace('_', '') === 'PUCCR'
        ? 'Escolta-ho ara'
        : 'Mira-ho ara';
};
const getBoto = (text, icona, route, index, tema) => {
    return (
        <Boto
            text={text}
            icona={icona}
            isIconaDreta={!!icona}
            altIcona={`icona ${icona}`}
            tag='a'
            mida={24}
            estil='ple'
            tema={tema}
            fons='fosc'
            className={styles.boto}
            route={route}
            dataTestId={`carrussel-enllac-boto-${index}`}
        />
    );
};
const getRoute = (element, isDirecteVideo, isDirecteRadio) => {
    const tipusDirecte = isDirecteVideo ? 'tv' : isDirecteRadio ? 'radio' : null;

    return {
        id: element.id,
        slug: element.nombonic || element.nom_friendly || element.permatitle || element.titol,
        tipologia: element.tipologia,
        domini: element.domini,
        canals: element.canals,
        href: element.tipologia !== 'WCR_PROGRAMA' && element.tipologia !== 'PTVC_PROGRAMA' ? element.url : null,
        agrupadores: element.agrupacions,
        tipusDirecte: tipusDirecte
    };
};

const _getDurada = (durada) => {
    return durada ? (
        <div className={styles.durada}>
            <Icona
                className={styles.ico_clock}
                icona='rellotge'
                ariaHidden={true}
                isBlanc={true}
                alt='Icona rellotge'
                mida={16}
            />
            <div>
                <span className='sr-only'>Durada: </span>
                {_sliceDurada(durada)}
            </div>
        </div>
    ) : null;
};

const _getCaducitat = (caducitat, i) => {
    const dataCaducitat = getCaducitat(caducitat);
    return caducitat && dataCaducitat ? (
        <div data-testid={`carrussel-data-caducitat-${i}`} className={styles.disponible}>
            {dataCaducitat}
        </div>
    ) : null;
};

const _getCodiEtic = (codis_etics) => {
    return codis_etics?.[0] ? <CodiEspectador codi={codis_etics[0].id} alt={codis_etics[0].desc} mida={24} /> : null;
};

const _getEtiqueta = (props) => {
    const { avantitol, isPelicula, isDestacat, isDocumental, etiquetes, programesTv, programesRadio } = props;
    const etiquetaMain = etiquetes?.find((et) => et.main === true);
    const text = isPelicula
        ? 'Pel·lícula'
        : isDocumental
        ? 'Documental'
        : isDestacat && avantitol
        ? avantitol
        : etiquetaMain?.desc
        ? etiquetaMain.desc
        : __isEmpty(programesTv) && __isEmpty(programesRadio) && avantitol
        ? avantitol
        : null;
    return text ? (
        <span className={styles.etiqueta} aria-label={text}>
            {text}
        </span>
    ) : null;
};

const _getTitol = (titol, hasLink, imatgesLogo, route, tipologia, index) => {
    const nodeTitol = <h2 className={styles.titol}>{removeHtmlTags(titol)}</h2>;
    const showTitol = tipologia === 'DTY_VIDEO_MM' || tipologia === 'WCR_AUDIO_MM' || __isEmpty(imatgesLogo);
    if (showTitol) {
        if (hasLink) {
            return (
                <Enllac route={route} dataTestId={`carrussel-enllac-titol-${index}`}>
                    {nodeTitol}
                </Enllac>
            );
        } else if (__isEmpty(imatgesLogo)) {
            return nodeTitol;
        }
    } else {
        return null;
    }
};

const _getTitolPrograma = (programes_tv, programes_radio, isPelicula, imatgesLogo) => {
    const titolPrograma = programes_tv?.[0]?.desc ? programes_tv?.[0].desc : programes_radio?.[0]?.desc;
    return titolPrograma && __isEmpty(imatgesLogo) && !isPelicula ? (
        <div className={styles.titolPrograma}>{titolPrograma}</div>
    ) : null;
};

const _getCallToAction = (
    isDestacat,
    isDirecteVideo,
    isDirecteFuturVideo,
    isDirecteRadio,
    isDirecteFuturRadio,
    element,
    route,
    tipologia,
    index,
    tema
) => {
    if (isDirecteVideo) {
        return getBoto('Mira-ho en directe', 'directes_ple', route, index, tema);
    } else if (isDirecteRadio) {
        return getBoto('Escolta-ho en directe', 'directes_ple', route, index, tema);
    } else if (tipologia === 'DTY_VIDEO_MM') {
        return getBoto('Mira-ho ara', 'play', route, index, tema);
    } else if (tipologia === 'WCR_AUDIO_MM') {
        return getBoto('Escolta-ho ara', 'auriculars_ple', route, index, tema);
    } else if (tipologia === 'PTVC_PROGRAMA' || tipologia === 'WCR_PROGRAMA') {
        return getBoto('Ves-hi ara', false, route, index, tema);
    } else if ((isDirecteFuturVideo || isDirecteFuturRadio) && element.call_to_action) {
        return getBoto(_textBoto(element), false, route, index, tema);
    } else if (isDestacat) {
        return getBoto(_textBoto(element), false, route, index, tema);
    }
};

const _getNodeLogo = (logos_canals, imatgesLogo, route, programa_tv, programa_radio, titol, index) => {
    if (logos_canals?.[0]) {
        const src = getLogoDirecte(logos_canals?.[0].id);
        const alt = `logo del canal ${logos_canals?.[0].desc}`;
        return (
            <div className={styles.logodirecte}>
                <Imatge src={src} layout='fill' objectFit='contain' alt={alt} mostrarRecurs={false} />
            </div>
        );
    } else if (!__isEmpty(imatgesLogo)) {
        const programa = programa_tv?.[0]?.desc || programa_radio?.[0]?.desc || titol;
        return (
            <div className={styles.logo}>
                <Enllac route={route} dataTestId={`carrussel-enllac-logo-${index}`}>
                    <Imatge
                        imatges={imatgesLogo}
                        tipus='web_logo'
                        layout='fill'
                        alt={`logo del programa ${programa}`}
                        objectFit='contain'
                        mostrarRecurs={false}
                    />
                </Enllac>
            </div>
        );
    } else {
        return null;
    }
};

export default function Carrussel(props) {
    const { items, tema, testId } = props;
    const { state: userState } = useUser();
    const [initialSlide, setInitialSlide] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        setInitialSlide(parseInt(userState?.positionCarrussel) || 0);
    }, [userState]);

    useEffect(() => {
        if (initialSlide > 0) {
            sliderRef.current.swiper.slideTo(initialSlide + 1);
        }
    }, [initialSlide]);

    const [swiperInit, setSwiperInit] = useState(false);

    let resizeTimeOut;

    const stylesModul = clsx({
        [styles.overlay]: true
    });

    const stylesSlide = clsx({
        [styles.slide]: true,
        [styles.init]: swiperInit
    });

    const _renderItems = () => {
        if (!__isEmpty(items)) {
            return Array.from(items)
                .slice(0, 12)
                .map((element, index) => {
                    const isDestacat = element.tipologia === 'DTY_DESTACAT';
                    const hasAgrupadoraDirecte = element.agrupacions?.some((agrupadora) => agrupadora.id === 'DIRECTE3CAT');
                    const canalRadio = element.canals?.[0].id.replace('_', '');
                    const hasCanalRadio =
                        canalRadio === 'PUCCR' ||
                        canalRadio === 'PUCCREI' ||
                        canalRadio === 'PUCICAT' ||
                        canalRadio === 'PUCCM' ||
                        canalRadio === 'PUCCI';
                    const hasCanalVideo =
                        (!__isEmpty(element.logos_canals?.[0].id) || !__isEmpty(element.canals?.[0].id)) && !hasCanalRadio;
                    const isDirecteVideo = isDestacat && hasCanalVideo && hasAgrupadoraDirecte;
                    const isDirecteFuturVideo = isDestacat && hasCanalVideo && !hasAgrupadoraDirecte;
                    const isDirecteRadio = isDestacat && hasCanalRadio && hasAgrupadoraDirecte;
                    const isDirecteFuturRadio = isDestacat && hasCanalRadio && !hasAgrupadoraDirecte;
                    const isDirecte = isDirecteVideo || isDirecteFuturVideo || isDirecteRadio || isDirecteFuturRadio;
                    const hasLink = !(isDirecteFuturVideo && !element.url) && !(isDirecteFuturRadio && !element.url);
                    const route = hasLink ? getRoute(element, isDirecteVideo, isDirecteRadio) : {};
                    const titol = removeHtmlTags(!__isEmpty(element.titol_promo) ? element.titol_promo : element.titol);
                    const entradeta = trimBR(!__isEmpty(element.entradeta_promo) ? element.entradeta_promo : element.entradeta);
                    const isPelicula = isPeli(element.formats?.[0].id);
                    const isDocumental = hasEtiquetaDocumental(
                        element.formats,
                        isPelicula,
                        element.programes_tv?.[0]?.desc,
                        element.programes_radio?.[0]?.desc,
                        element.tipologia
                    );
                    const imatgesLogo = getLogoDiferit(element);
                    const infoDistribucio = element.info_distribucio;
                    const codiEtic =
                        element.codis_etics && element.codis_etics[0].id != 'CE_TP' && _getCodiEtic(element.codis_etics);
                    const durada = _getDurada(element.durada);
                    const dataCaducitat = !isDirecte && _getCaducitat(element.data_caducitat, index);
                    return (
                        <SwiperSlide key={element.id} className={stylesSlide} data-testid={`swiper-slide-${index}`}>
                            <div className={stylesModul}>
                                <div className={styles.descripcio}>
                                    {_getTitolPrograma(element.programes_tv, element.programes_radio, isPelicula, imatgesLogo)}
                                    {_getEtiqueta({
                                        avantitol: element.avantitol || element.programa,
                                        isPelicula,
                                        isDestacat,
                                        isDocumental,
                                        etiquetes: element.etiquetes,
                                        programesTv: element.programes_tv,
                                        programesRadio: element.programes_radio
                                    })}
                                    {_getNodeLogo(
                                        element.logos_canals,
                                        imatgesLogo,
                                        route,
                                        element.programes_tv,
                                        element.programes_radio,
                                        element.titol,
                                        index
                                    )}
                                    {infoDistribucio && (
                                        <div data-testid='infodistribucio' className={styles.infoDistribucio}>
                                            {infoDistribucio}
                                        </div>
                                    )}
                                    {_getTitol(titol, hasLink, imatgesLogo, route, element.tipologia, index)}

                                    {(codiEtic || durada || dataCaducitat) && (
                                        <div className={styles.duradaWrapper}>
                                            {codiEtic}
                                            {durada}
                                            {dataCaducitat}
                                        </div>
                                    )}

                                    <p className={styles.entradeta}>{removeHtmlTags(entradeta)}</p>
                                    {hasLink && (
                                        <div className={styles.botoAfegir}>
                                            {_getCallToAction(
                                                isDestacat,
                                                isDirecteVideo,
                                                isDirecteFuturVideo,
                                                isDirecteRadio,
                                                isDirecteFuturRadio,
                                                element,
                                                route,
                                                element.tipologia,
                                                index,
                                                tema
                                            )}
                                            {element.tipologia !== 'DTY_DESTACAT' &&
                                                !isDirecteFuturVideo &&
                                                !isDirecteFuturRadio && (
                                                    <AfegeixALaLlista
                                                        className={styles.afegeix}
                                                        tooltipPosition='right'
                                                        positionCarrussel={index}
                                                        id={element.id}
                                                        tipologia={element.tipologia}
                                                    />
                                                )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Imatge
                                imatges={element.imatges}
                                tipus='web_carrussel'
                                alt={element.titol}
                                layout='fill'
                                objectFit='cover'
                                priority={index === 0}
                                className={styles.imatgeCarrussel}
                            />
                        </SwiperSlide>
                    );
                });
        }
        return;
    };
    const hasAutoplay = items?.length > 1 ? { delay: 10000, pauseOnMouseEnter: false } : false;
    const hasNavigation = items?.length > 1;

    return (
        <div className={styles.carrussel} data-testid={testId}>
            <Swiper
                ref={sliderRef}
                centeredSlides={true}
                spaceBetween={0}
                slidesPerView={1}
                slidesPerGroup={1}
                onAfterInit={() => {
                    setSwiperInit(true);
                }}
                pagination={{ clickable: true }}
                modules={[Keyboard, Pagination, Autoplay, Navigation]}
                simulateTouch={true}
                autoplay={hasAutoplay}
                loop={hasNavigation}
                navigation={hasNavigation}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            swiper.update();
                        }, 100);
                    });
                }}
                keyboard={{
                    enabled: true
                }}
            >
                {_renderItems()}
            </Swiper>
        </div>
    );
}

Carrussel.defaultProps = {
    items: {},
    tema: 'T-3cat',
    testId: 'carrussel'
};
